<template>
  <search-field v-model="myValue" :search-label="label" :person-filter="personTypes" :include-photo="includePhoto" :value-field="valueField === 'username' ? 'ldap' : valueField" :name-format-last-first="nameFormatLastFirst" :rules="rules"></search-field>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  components: {
    SearchField: () => import('@/components/greatscots/searchField')
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    personTypes: {
      type: Array,
      default: () => { return [] }
    },
    includePhoto: {
      type: Boolean,
      default: false
    },
    valueField: String,
    nameFormatLastFirst: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const myValue = ref(props.value)
    watch(myValue, (val) => {
      // console.log('DirectorySearch myValue changed to ' + myValue + '; prop value: ' + props.value)
      if (val.value !== props.value) emit('input', val.value)
    })

    const rules = computed(() => {
      if (props.required) return [v => !!v || 'Required']
      else return []
    })

    return {
      myValue,
      rules
    }
  }
}
</script>
